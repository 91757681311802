import React, { useState, useEffect } from "react";
import Layout from "@layout";
import SEO from "@components/seo";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils/functions";
import { StaticImage } from "gatsby-plugin-image";
import Button from "../components/shared/button";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import { orderBy } from "lodash";
import PageBreadcrumb from "../components/pagebreadcrumb";
import { Auth, API } from "aws-amplify";
import { listUserProfiles } from "../graphql/queries";

const DuelRankingsPage = ({ data, location, pageContext }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    const user = useSelector((state) => state.user.value);
    const [profiles, setProfiles] = useState(
        orderBy(data?.appSync?.listUserProfiles?.items, "duelRating", "desc")
    );
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                API.graphql({
                    query: listUserProfiles,
                    variables: { limit: 2000 },
                })
                    .then((result) => {
                        setProfiles(
                            orderBy(
                                result.data.listUserProfiles.items,
                                "duelRating",
                                "desc"
                            )
                        );
                    })
                    .catch((err) => console.log(err));
            })
            .catch((err) => {});
    }, []);
    const columns = [
        {
            name: "Player Name",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "Rating",
            selector: (row) => row.duelRating,
            sortable: true,
        },
    ];

    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            <SEO title="Duelist Rankings" pathname="/duel-rankings" />
            <PageBreadcrumb
                pageContext={pageContext}
                location={location}
                title="Duelist Ratings"
            />
            <div>
                <div className="container text-center">
                    <div className="pb-60">
                        <DataTable
                            columns={columns}
                            data={profiles}
                            striped
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

DuelRankingsPage.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};
export const query = graphql`
    query DuelRankingsPageQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        appSync {
            listUserProfiles(limit: 2000) {
                items {
                    id
                    name
                    duelRating
                    avatar
                }
            }
        }
    }
`;
export default DuelRankingsPage;
